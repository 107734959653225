import React, {useState, useEffect} from 'react'
import {Button, Drawer, Input, Message, Row, Col} from 'rsuite'
import {EmbedBlockValueSingleLang, EmbedPreview} from '../blocks/embedBlock'
import {EmbedType, OtherEmbed} from '../blocks/types'
import {useTranslation} from 'react-i18next'
import {destructUnionCase} from '../utility'

export interface EmbedEditPanel {
  readonly value: EmbedBlockValueSingleLang
  readonly embedCode: string
  onClose(): void
  onConfirm(value: EmbedBlockValueSingleLang): void
}

export function EmbedEditPanel({value, embedCode, onClose, onConfirm}: EmbedEditPanel) {
  const [errorMessage, setErrorMessage] = useState<string>()
  const [input, setInput] = useState(() => embedCode)
  const [embed, setEmbed] = useState<EmbedBlockValueSingleLang>(value)

  const {unionCase, value: val} = destructUnionCase(embed.type)
  const isEmpty = unionCase === EmbedType.Other && !(val as OtherEmbed).url

  const {t} = useTranslation()

  useEffect(() => {
    setErrorMessage(undefined)
    if (!input) {
      return
    }

    const vimeoMatch = input.match(/vimeo.com\/([0-9]+)/)
    const youTubeMatch = input.match(/youtube.com\/watch\?v=([0-9a-zA-Z-_]+)/)
    const youTubeEmbedMatch = input.match(/youtube.com\/embed\/([0-9a-zA-Z-_]+)/)

    if (vimeoMatch) {
      const [, videoID] = vimeoMatch
      setEmbed({embedCode: input, type: {[EmbedType.VimeoVideo]: {videoID}}})
    } else if (youTubeMatch) {
      const [, videoID] = youTubeMatch
      setEmbed({embedCode: input, type: {[EmbedType.YouTubeVideo]: {videoID}}})
    } else if (youTubeEmbedMatch) {
      const [, videoID] = youTubeEmbedMatch
      setEmbed({embedCode: input, type: {[EmbedType.YouTubeVideo]: {videoID}}})
    } else {
      if (input) {
        const parser = new DOMParser()
        const element = parser.parseFromString(input, 'text/html')
        const [iframe] = element.getElementsByTagName('iframe')

        if (iframe) {
          // add iframe attributes if set in input
          const setEmbedOther = {
            title: iframe.title,
            width: iframe.width ? parseInt(iframe.width) : undefined,
            height: iframe.height ? parseInt(iframe.height) : undefined,
            styleCustom: !!iframe.style && !!iframe.style.cssText ? iframe.style.cssText : '',
          }
          setEmbed({
            embedCode: input,
            type: {[EmbedType.Other]: {url: iframe.src, ...setEmbedOther}},
          })
        } else {
          try {
            setEmbed({
              embedCode: input,
              type: {[EmbedType.Other]: {url: new URL(input).toString()}},
            })
          } catch {
            setEmbed({embedCode: input, type: {[EmbedType.Other]: {}}})
            setErrorMessage(t('embeds.panels.invalidURL'))
          }
        }
      } else {
        setEmbed({embedCode: input, type: {[EmbedType.Other]: {}}})
      }
    }
  }, [input])

  return (
    <>
      <Drawer.Header>
        <Drawer.Title>{t('blocks.embeds.panels.editEmbed')}</Drawer.Title>

        <Drawer.Actions>
          <Button
            appearance={'primary'}
            color={'green'}
            disabled={isEmpty}
            onClick={() => onConfirm(embed)}>
            {t('blocks.embeds.panels.confirm')}
          </Button>
          <Button appearance={'subtle'} onClick={() => onClose?.()}>
            {t('blocks.embeds.panels.close')}
          </Button>
        </Drawer.Actions>
      </Drawer.Header>

      <Drawer.Body>
        <Row className="show-grid" style={{width: '100%'}}>
          <Col xs={13}>
            <Input
              as="textarea"
              rows={4}
              placeholder={t('blocks.embeds.panels.embed')}
              value={input}
              onChange={(input) => setInput(input)}
            />
            {errorMessage && <Message type="error">{errorMessage}</Message>}
            <div style={{marginBottom: 8, marginTop: 20}}>
              <p>{t('blocks.embeds.panels.socialMediaList')}</p>
              <code>{t('blocks.embeds.panels.fbPosts')}</code>
              <p>{t('blocks.embeds.panels.embedCodeAttributes')}</p>
              <code>{t('blocks.embeds.panels.iframe1Sample')}</code>
              <p>{t('blocks.embeds.panels.alternativeStyling')}</p>
              <code>{t('blocks.embeds.panels.iframe2Sample')}</code>
              <p>{t('blocks.embeds.panels.GDPRInfo')}</p>
            </div>
          </Col>
          <Col xs={1}></Col>
          <Col xs={10}>
            <EmbedPreview value={embed} />
          </Col>
        </Row>
      </Drawer.Body>
    </>
  )
}
