import React, {Fragment, useState, ReactNode, useMemo, memo} from 'react'
import {UnionToIntersection} from '@dudagroup/editor/lib/client/router'
import {AddBlockInput} from './addBlockInput'
import {IconButton, Panel, Whisper, Tooltip, Dropdown} from 'rsuite'
import {IconProps} from '@rsuite/icons/lib/Icon'
import {
  ContentEditAction,
  ContentEditActionEnum,
} from '@dudagroup/editor/lib/client/control/contentReducer'
import {Configs, generateEmptyContent} from '@dudagroup/editor'
import {BlockMap} from '../blocks/blockMap'
import {SchemaPath} from '@dudagroup/editor/lib/client/interfaces/utilTypes'
import {copyToClipboard, destructUnionCase, generateID, pasteFromClipboard} from '../utility'
import {ContentModelSchemaFieldObject} from '@dudagroup/editor/lib/client/interfaces/contentModelSchema'
import {ListWrapper} from '@dudagroup/editor/lib/client/interfaces/extensionConfig'
import {useTranslation} from 'react-i18next'
import {BlockValue} from '../blocks/types'

import CaretUp from '@rsuite/icons/legacy/CaretUp'
import Trash from '@rsuite/icons/legacy/Trash'
import Copy from '@rsuite/icons/legacy/Copy'
import EllipsisV from '@rsuite/icons/legacy/EllipsisV'
import CaretDown from '@rsuite/icons/legacy/CaretDown'

export interface BlockProps<V = any> {
  value: V
  model: ContentModelSchemaFieldObject
  dispatch: React.Dispatch<ContentEditAction>
  path: SchemaPath
  onChange: (value: any, path: SchemaPath) => void
  autofocus?: boolean
  disabled?: boolean
  configs: Configs
  languageLaneL: string
  languageLaneR: string
  langUi: string
}

export type BlockConstructorFn<V = any> = (props: BlockProps<V>) => JSX.Element

export interface BlockCaseProps<V = any> {
  label: string
  icon: React.ReactElement<IconProps>
  field: BlockConstructorFn<V>
}

export interface BlockListValue<T extends string = string, V = any> {
  [unionCase: string]: V
}

export type BlockMap = Record<string, BlockCaseProps>

export type BlockMapForValue<R extends BlockListValue> = UnionToIntersection<
  R extends BlockListValue<infer T, infer V> ? {[K in T]: BlockCaseProps<V>} : never
>

export interface BlockListItemProps<T extends string = string, V = any> {
  index: number
  value: BlockListValue<T, V>
  model: ContentModelSchemaFieldObject
  path: SchemaPath
  label: string
  icon: React.ReactElement<IconProps>
  autofocus: boolean
  disabled?: boolean
  dispatch: React.Dispatch<ContentEditAction>
  unionCase: string
  children: (props: BlockProps<V>) => JSX.Element
  configs: Configs
  languageLaneL: string
  languageLaneR: string
  langUi: string
  hasNextIndex: boolean
}

export const BlockListItem = memo(
  function BlockListItem({
    index,
    value,
    model,
    label,
    icon,
    unionCase,
    dispatch,
    autofocus,
    disabled,
    children,
    configs,
    languageLaneL,
    languageLaneR,
    langUi,
    hasNextIndex,
  }: BlockListItemProps) {
    return (
      <ListItemWrapper
        label={label}
        icon={icon}
        disabled={disabled}
        onCopy={async () => {
          const val = {...value}
          try {
            await copyToClipboard(JSON.stringify({[unionCase]: val}))
          } catch (error) {
            console.error(error)
          }
        }}
        onDelete={() => {
          dispatch({
            type: ContentEditActionEnum.splice,
            start: index,
            path: ['blocks'],
            delete: 1,
          })
        }}
        onMoveUp={() => {
          if (index >= 1) {
            dispatch({
              type: ContentEditActionEnum.swap,
              path: ['blocks'],
              oldIndex: index,
              newIndex: index - 1,
            })
          }
        }}
        onMoveDown={() => {
          if (hasNextIndex) {
            dispatch({
              type: ContentEditActionEnum.swap,
              path: ['blocks'],
              oldIndex: index,
              newIndex: index + 1,
            })
          }
        }}>
        {children({
          value,
          model,
          path: ['blocks', index, 'content', unionCase],
          dispatch,
          configs,
          onChange: (value, path) => {
            dispatch({
              type: ContentEditActionEnum.update,
              path: ['blocks', index, 'content', unionCase].concat(path),
              value,
            })
          },
          autofocus,
          disabled,
          languageLaneL,
          languageLaneR,
          langUi,
        })}
      </ListItemWrapper>
    )
  },
  (prev, next) => {
    return (
      Object.is(prev.index, next.index) &&
      Object.is(prev.value, next.value) &&
      Object.is(prev.model, next.model) &&
      Object.is(prev.autofocus, next.autofocus) &&
      Object.is(prev.disabled, next.disabled) &&
      Object.is(prev.languageLaneL, next.languageLaneL) &&
      Object.is(prev.languageLaneR, next.languageLaneR)
    )
  }
)

export function useBlockMap<V extends BlockListValue>(
  map: () => BlockMapForValue<V>,
  deps: ReadonlyArray<any> | undefined
) {
  return useMemo(map, deps)
}

export interface BlockListProps<V extends BlockListValue> {
  value: BlockListRoot<V>
  autofocus?: boolean
  disabled?: boolean
  dispatch: React.Dispatch<ContentEditAction>
  configs: Configs
  blockCases: any
  languageLaneL: string
  languageLaneR: string
  langUi: string
}

export interface BlockListRoot<V> {
  blocks: ListWrapper<{[unionCase: string]: V}>[]
}

export function BlockList<V extends BlockListValue>({
  value: values,
  disabled,
  dispatch,
  configs,
  blockCases,
  languageLaneL,
  languageLaneR,
  langUi,
}: BlockListProps<V>) {
  const [focusIndex, setFocusIndex] = useState<number | null>(null)
  const blockMap = useBlockMap<BlockValue>(() => BlockMap, []) as BlockMap

  function addButtonForIndex(index: number) {
    return (
      <div
        style={{
          paddingLeft: 30,
          paddingRight: 30,
          marginTop: 10,
          marginBottom: 10,
          textAlign: 'center',
        }}>
        <AddBlockInput
          menuItems={Object.entries(blockMap)
            .filter(([type]) => {
              return type in blockCases
            })
            .map(([type, {icon, label}]) => ({
              id: type,
              icon,
              label,
            }))}
          onMenuItemClick={({id}: {id: string}) => {
            const listWrapper: ListWrapper = {
              id: generateID(),
              expanded: false,
              content: {
                [id]: generateEmptyContent(blockCases[id], configs.apiConfig.languages),
              },
            }

            dispatch({
              type: ContentEditActionEnum.splice,
              path: ['blocks'],
              start: index,
              delete: 0,
              insert: [listWrapper],
            })
            setFocusIndex(index)
          }}
          onPaste={async () => {
            let val
            try {
              val = await pasteFromClipboard()
            } catch (error) {
              console.error(error)
            }
            if (val) {
              const listWrapper: ListWrapper = {
                id: generateID(),
                expanded: false,
                content: JSON.parse(val),
              }

              dispatch({
                type: ContentEditActionEnum.splice,
                path: ['blocks'],
                start: index,
                delete: 0,
                insert: [listWrapper],
              })
              setFocusIndex(index)
            }
          }}
          subtle={index !== values.blocks?.length || disabled}
          disabled={disabled}
        />
      </div>
    )
  }

  function listItemForIndex(listWrapper: ListWrapper<V>, index: number) {
    const value = listWrapper.content
    const hasPrevIndex = index - 1 >= 0
    const hasNextIndex = index + 1 < values.blocks.length
    const {unionCase, value: val} = destructUnionCase(value)
    const blockDef = blockMap[unionCase]

    return (
      <Fragment key={listWrapper.id + '_' + index}>
        <BlockListItem
          configs={configs}
          index={index}
          value={val}
          model={blockCases[unionCase]}
          path={['blocks', index, 'content', unionCase]}
          unionCase={unionCase}
          label={blockDef.label}
          icon={blockDef.icon}
          dispatch={dispatch}
          autofocus={focusIndex === index}
          disabled={disabled}
          languageLaneL={languageLaneL}
          languageLaneR={languageLaneR}
          langUi={langUi}
          hasNextIndex={hasNextIndex}>
          {blockDef.field}
        </BlockListItem>
        {addButtonForIndex(index + 1)}
      </Fragment>
    )
  }

  return (
    <div
      style={{
        width: '100%',
      }}>
      {addButtonForIndex(0)}
      {values.blocks?.map((value: any, index) => listItemForIndex(value, index))}
    </div>
  )
}

interface ListItemWrapperProps {
  children?: ReactNode
  icon?: React.ReactElement<IconProps>
  label: string
  disabled?: boolean

  onDelete?: () => void
  onCopy?: () => void
  onMoveUp?: () => void
  onMoveDown?: () => void
}

function ListItemWrapper({
  children,
  label,
  icon,
  disabled,
  onDelete,
  onCopy,
  onMoveUp,
  onMoveDown,
}: ListItemWrapperProps) {
  const {t} = useTranslation()
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginRight: 10,
        }}>
        <Dropdown
          placement="bottomStart"
          renderToggle={(props, ref) => (
            <IconButton
              {...props}
              ref={ref}
              circle
              icon={<EllipsisV style={{fontSize: '1em'}} />}
            />
          )}>
          <Dropdown.Item icon={<Copy />} onClick={onCopy} disabled={disabled}>
            {t('blockList.copy')}
          </Dropdown.Item>
          <Dropdown.Item
            icon={<Trash />}
            onClick={onDelete}
            disabled={onDelete == null || disabled}>
            {t('blockList.delete')}
          </Dropdown.Item>
        </Dropdown>
        <div style={{flexGrow: 1}} />
        <div style={{marginTop: 10, marginBottom: 5}}>
          <IconButton
            circle
            icon={<CaretUp style={{fontSize: '1em'}} />}
            onClick={onMoveUp}
            disabled={onMoveUp == null || disabled}
          />
        </div>
        <div style={{marginBottom: 10}}>
          <IconButton
            circle
            title="Down"
            icon={<CaretDown style={{fontSize: '1em'}} />}
            onClick={onMoveDown}
            disabled={onMoveDown == null || disabled}
          />
        </div>
        <div style={{flexGrow: 1}} />
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
        }}>
        <Panel style={{width: '100%'}} bordered={true}>
          <div style={{padding: 10}}>{children}</div>
        </Panel>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: 10,
          fontSize: 24,
          fill: 'gray',
        }}>
        {icon && (
          <Whisper placement="top" trigger="hover" speaker={<Tooltip>{label}</Tooltip>}>
            <div style={{fontSize: '0.7em'}}>{icon}</div>
          </Whisper>
        )}
      </div>
    </div>
  )
}
