import {loadavg} from 'os'
import React, {useEffect, useState} from 'react'
import {Button, Dropdown} from 'rsuite'
import {useExportEmployeesQuery} from '../../../api'
import {useTranslation} from 'react-i18next'

interface employeeCsvInfo {
  content: {
    name: string
    divisionList_DEPRECATED?: any[]
    _function: {de: string; fr: string; en: string}
    jobTitle: {de: string; fr: string; en: string}
    organizationUnitList?: any[]
    phoneNumber: string
    eMail: string
    image?: {
      media: {
        id: string
        url: string
      }
    }
    hide: boolean
    hideImage: boolean
  }
  publicationState: string
  publicationDate?: string
  dePublicationDate?: string
  createdAt: string
}

enum employeeState {
  All,
  Published,
  Depublished,
  Archived,
  Draft,
}

export default function CustomViewExportEmployees() {
  const {data, refetch, loading, error} = useExportEmployeesQuery({
    fetchPolicy: 'no-cache',
  })

  const [blob, setBlob] = useState<Blob>()
  const {t} = useTranslation()
  const [filterState, setFilterState] = useState<employeeState>(employeeState.All)

  const date = new Date()
  const timestamp = date.toLocaleDateString('de-CH').split('.').reverse().join('')

  useEffect(() => {
    if (blob) {
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `employees_${timestamp}.csv`)

      // Append to html link element page
      document.body.appendChild(link)

      // Start download
      link.click()

      // Clean up and remove the link
      link?.parentNode?.removeChild(link)
    }
  }, [blob, timestamp])

  const downloadCSV = async () => {
    const {data} = await refetch()

    if (data) {
      const csvHeader =
        'name; organizationUnitDe; organizationUnitFr; organizationUnitEn; division_De; division_Fr; division_En; division_De_DEPRECATED; division_Fr_DEPRECATED; division_En_DEPRECATED; functionDe; functionFr; functionEn; jobTitleDe; jobTitleFr; jobTitleEn,phoneNumber; eMail; imageUrl; imageId; hideEmployee; hideImage; status; createdAt'
      console.log(
        smushData(data.content.employee.list.nodes, filterState).length,
        data.content.employee.list.nodes.length
      )

      const csvBody = smushData(data.content.employee.list.nodes, filterState)
        .map((e: string[]) => e.join(';'))
        .join('\n')
      const csvData = csvHeader + '\n' + csvBody
      const blob = new Blob([csvData], {type: 'octet/stream'})
      setBlob(() => blob)
    }
  }

  const getTitle = () => {
    switch (filterState) {
      case employeeState.All:
        return `${t('exportEmployees.state')}: ${t('exportEmployees.all')}`
      case employeeState.Published:
        return `${t('exportEmployees.state')}: ${t('exportEmployees.published')}`
      case employeeState.Depublished:
        return `${t('exportEmployees.state')}: ${t('exportEmployees.depublished')}`
      case employeeState.Archived:
        return `${t('exportEmployees.state')}: ${t('exportEmployees.archived')}`
      // case employeeState.Draft:
      //   return `${t('exportEmployees.state')}: ${t('exportEmployees.draft')}`
      default:
        return `${t('exportEmployees.state')}: ${t('exportEmployees.all')}`
    }
  }

  return (
    <>
      <h3>{t('exportEmployees.title')}</h3>
      <br />
      <br />
      <div>
        {error && <p>{t('exportEmployees.error')}</p>}
        {loading && <p>{t('exportEmployees.loading')}</p>}
      </div>
      <br />
      <div>
        <Dropdown title={getTitle()}>
          <Dropdown.Item onClick={() => setFilterState(employeeState.All)}>
            {t('exportEmployees.all')}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setFilterState(employeeState.Published)}>
            {t('exportEmployees.published')}
          </Dropdown.Item>
          <Dropdown.Item onClick={() => setFilterState(employeeState.Depublished)}>
            {t('exportEmployees.depublished')}
          </Dropdown.Item>
          {/* <Dropdown.Item onClick={() => setFilterState(employeeState.Draft)}>
            {t('exportEmployees.draft')}
          </Dropdown.Item> */}
          <Dropdown.Item onClick={() => setFilterState(employeeState.Archived)}>
            {t('exportEmployees.archived')}
          </Dropdown.Item>
        </Dropdown>

        <br />
        <br />

        <Button
          appearance="primary"
          size="lg"
          onClick={downloadCSV}
          style={{
            marginBottom: '1rem',
          }}>
          {t('exportEmployees.download')}
        </Button>
      </div>
    </>
  )
}

const getPublicationState = (
  publicationState: string,
  publicationDateString?: string,
  depublicationDateString?: string
) => {
  if (publicationState === 'Published' && publicationDateString) {
    const publicationDate = new Date(publicationDateString)
    const now = new Date()

    if (!publicationDate || now.getTime() >= publicationDate.getTime()) {
      // Check if the article is depublicated
      if (depublicationDateString) {
        const depublicationDate = new Date(depublicationDateString)
        if (!depublicationDate || now.getTime() < depublicationDate.getTime()) {
          return 'Published'
        } else {
          return 'Depublished'
        }
      } else {
        return 'Published'
      }
    } else {
      return 'Pending'
    }
  } else {
    return publicationState
  }
}

const smushData = (data: any, filterState: employeeState) => {
  const employeeArray = data
    .filter((itemArr: employeeCsvInfo[]) => {
      const item = itemArr[0]
      // Exclude items with publicationState 'Trash' or 'Draft'
      return item.publicationState !== 'Trash' && item.publicationState !== 'Draft'
    })
    .filter((itemArr: employeeCsvInfo[]) => {
      const item = itemArr[0]

      if (filterState === employeeState.All) {
        return true
      } else if (filterState === employeeState.Published) {
        const depublicationDate = new Date(item?.dePublicationDate || '')
        const now = new Date()

        if (item.publicationState === 'Published') {
          if (!isNaN(depublicationDate.getTime())) {
            if (now.getTime() >= depublicationDate.getTime()) {
              return false
            }
          }

          return true
        }
        return false
      } else if (filterState === employeeState.Archived) {
        return item.publicationState === 'Archived'
      } else if (filterState === employeeState.Depublished) {
        const depublicationDate = new Date(item?.dePublicationDate || '')
        const now = new Date()
        if (depublicationDate && now.getTime() >= depublicationDate.getTime()) {
          return true
        }
        return false
      } else if (filterState === employeeState.Draft) {
        return item.publicationState === 'Draft'
      }

      return true
    })
    .map((itemArr: employeeCsvInfo[]) => {
      /**
       * Structure is a bit wonky atm, as there are parallel ways to express the same employee data.
       * For now.
       * While the client takes it's time to clean up it's database, we need to filter it:
       *
       * 1. Organization Units win:
       * if there is one or multiple oranizationUnit defined
       * OR
       * if there are one or multiple organizationUnits/ AND one or moltiple defined
       * - create one row per organizationUnit, leave division(deprecated) fields empty
       * 2. if there are no organizationUnits and one or multiple division(deprecated) fields defined:
       * - return one row for each each division (deprecated) field and leave division (deprecated) fields empty
       * 3. if there are no organizationUnits defined and one division (deprecated) is defined
       * - return the employee-data and leave division (deprecated) fields empty
       */

      const item = itemArr[0]

      const stdValues = {
        name: item.content.name,
        organizationUnitDe: '',
        organizationUnitFr: '',
        organizationUnitEn: '',
        divisionDe: '',
        divisionFr: '',
        divisionEn: '',
        divisionDe_DEPRECATED: '',
        divisionFr_DEPRECATED: '',
        divisionEn_DEPRECATED: '',
        functionDe: item.content._function.de,
        functionFr: item.content._function.fr,
        functionEn: item.content._function.en,
        jobTitleDe: item.content.jobTitle.de,
        jobTitleFr: item.content.jobTitle.fr,
        jobTitleEn: item.content.jobTitle.en,
        phoneNumber: item.content.phoneNumber,
        eMail: item.content.eMail,
        imageUrl: item.content?.image?.media?.url || '',
        imageId: item.content?.image?.media?.id || '',
        hide: item.content.hide,
        hideImage: item.content.hideImage,
        status: getPublicationState(
          item.publicationState,
          item.publicationDate,
          item.dePublicationDate
        ),
        createdAt: item.createdAt,
      }

      const employeeArray = []

      const orgUnits_length = item.content.organizationUnitList?.length || 0

      const divisionDeprecated_length =
        orgUnits_length > 0 ? 0 : item.content.divisionList_DEPRECATED?.length || 0

      if (item.content.organizationUnitList && orgUnits_length) {
        for (let idx = 0; idx < orgUnits_length; idx++) {
          const obj = {
            name: stdValues.name,
            organizationUnitDe:
              item.content.organizationUnitList[idx].organizationUnit.record.content.title.de,
            organizationUnitFr:
              item.content.organizationUnitList[idx].organizationUnit.record.content.title.fr,
            organizationUnitEn:
              item.content.organizationUnitList[idx].organizationUnit.record.content.title.en,
            divisionDe:
              item.content.organizationUnitList[idx].organizationUnit.record.content?.division
                ?.record?.content?.title?.de,
            divisionFr:
              item.content.organizationUnitList[idx].organizationUnit.record.content?.division
                ?.record?.content?.title?.fr,
            divisionEn:
              item.content.organizationUnitList[idx].organizationUnit.record.content?.division
                ?.record?.content?.title?.en,
            divisionDe_DEPRECATED: '',
            divisionFr_DEPRECATED: '',
            divisionEn_DEPRECATED: '',
            functionDe: stdValues.functionDe,
            functionFr: stdValues.functionFr,
            functionEn: stdValues.functionEn,
            jobTitleDe: stdValues.jobTitleDe,
            jobTitleFr: stdValues.jobTitleFr,
            jobTitleEn: stdValues.jobTitleEn,
            phoneNumber: stdValues.phoneNumber,
            eMail: stdValues.eMail,
            imageUrl: stdValues.imageUrl || '',
            imageId: stdValues.imageId || '',
            hide: stdValues.hide,
            hideImage: stdValues.hideImage,
            status: stdValues.status,
            createdAt: stdValues.createdAt,
          }
          const arrValues = Object.values(obj)
          const escapedArr = arrValues.map((val) => JSON.stringify(val))

          employeeArray.push(escapedArr)
        }
      } else if (item.content.divisionList_DEPRECATED && divisionDeprecated_length) {
        for (let idx = 0; idx < divisionDeprecated_length; idx++) {
          const obj = {
            name: stdValues.name,
            organizationUnitDe: '',
            organizationUnitFr: '',
            organizationUnitEn: '',
            divisionDe: '',
            divisionFr: '',
            divisionEn: '',
            divisionDe_DEPRECATED:
              item.content.divisionList_DEPRECATED[idx].division?.record?.content?.title?.de || '',
            divisionFr_DEPRECATED:
              item.content.divisionList_DEPRECATED[idx].division?.record?.content?.title?.fr || '',
            divisionEn_DEPRECATED:
              item.content.divisionList_DEPRECATED[idx].division?.record?.content?.title?.en || '',
            functionDe: stdValues.functionDe,
            functionFr: stdValues.functionFr,
            functionEn: stdValues.functionEn,
            jobTitleDe: stdValues.jobTitleDe,
            jobTitleFr: stdValues.jobTitleFr,
            jobTitleEn: stdValues.jobTitleEn,
            phoneNumber: stdValues.phoneNumber,
            eMail: stdValues.eMail,
            imageUrl: stdValues.imageUrl || '',
            imageId: stdValues.imageId || '',
            hide: stdValues.hide,
            hideImage: stdValues.hideImage,
            status: stdValues.status,
            createdAt: stdValues.createdAt,
          }
          const arrValues = Object.values(obj)
          const escapedArr = arrValues.map((val) => JSON.stringify(val))

          employeeArray.push(escapedArr)
        }
      } else {
        const obj = stdValues
        const arrValues = Object.values(obj)
        const escapedArr = arrValues.map((val) => JSON.stringify(val))

        console.log(obj)

        employeeArray.push(escapedArr)
      }

      return employeeArray
    })
    .flat(1)
  return employeeArray
}
