import React, {useState} from 'react'
import {Button, ButtonToolbar, Form, Loader, Notification, Tag, toaster} from 'rsuite'
import {
  CopyDbDirection,
  useCopyDbMutation,
  useReindexMutation,
  useUploadPanelMembersMutation,
} from '../../../api'
import {FileDropInput} from '../common/fileDropInput'

import Upload from '@rsuite/icons/legacy/Upload'

export function CustomViewImporter() {
  const [file, setFile] = useState<File | null>(null)

  const [uploadImage, {loading}] = useUploadPanelMembersMutation()

  async function onSubmit() {
    const {errors} = await uploadImage({
      variables: {
        input: {file, filename: 'test'},
      },
    })

    if (errors) {
      toaster.push(
        <Notification
          closable
          type="error"
          header={errors.toString()}
          duration={5000}></Notification>,
        {placement: 'topEnd'}
      )
    } else {
      toaster.push(
        <Notification
          closable
          type="success"
          header="File imported"
          duration={5000}></Notification>,
        {placement: 'topEnd'}
      )
    }
  }

  async function handleDrop(files: File[]) {
    if (files.length === 0) return

    const file = files[0]
    // if (!file.type.startsWith('application')) {
    //   Notification.error({
    //     title: 'File type is wrong',
    //     duration: 5000
    //   })
    // }
    setFile(file)
  }

  return (
    <div>
      <h1>Panel Member CSV Importer</h1>
      <br></br>
      <br></br>
      <h4>Charset:</h4>
      utf-8
      <br />
      <br />
      <h4>Delimiter:</h4>
      ;
      <br />
      <br />
      <h4>The following Columns are required:</h4>
      EvaluationBodyId; EvaluationBodyNumber; EvaluationBodyName; PersonName; AcademicDegree_En;
      AcademicDegree_De; AcademicDegree_Fr; PersonNumber; IsResearchCouncilMember; University_En;
      University_De; University_Fr; Website; Function_En; Function_De; Function_Fr;
      MembershipValidFrom; MembershipValidUntil; EvaluationBodyName_En; EvaluationBodyName_De;
      EvaluationBodyName_Fr; EvaluationBodyType; EvaluationBodyPublishedFromDate;
      EvaluationBodyPublishedUntilDate; EvaluationBodySortNumber; FunctionSortNumber
      <br />
      <br />
      <Form>
        <Form.Group>
          <Form.ControlLabel>Upload CSV</Form.ControlLabel>
          <div
            style={{
              maxWidth: '250px',
              height: '160px',
            }}>
            <FileDropInput
              icon={loading ? <Loader /> : <Upload />}
              text={loading ? undefined : 'drag csv here'}
              disabled={loading}
              onDrop={handleDrop}
            />
          </div>

          {file ? (
            <>
              <br></br>
              <br></br>
              <Tag
                closable
                onClose={() => {
                  setFile(null)
                }}>
                {file.name}
              </Tag>
            </>
          ) : null}
        </Form.Group>
        <Form.Group>
          <ButtonToolbar>
            <Button
              appearance={file ? 'primary' : 'default'}
              disabled={!file || loading}
              onClick={() => {
                onSubmit().catch((e) => {
                  console.error(e)
                })
              }}>
              Submit
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </div>
  )
}

export function CustomViewReindex() {
  const [done, setDone] = useState(false)
  const [reindex, {error, loading}] = useReindexMutation()

  async function onSubmit() {
    await reindex({})

    if (error) {
      toaster.push(
        <Notification
          closable
          type="error"
          header={error.toString()}
          duration={5000}></Notification>,
        {placement: 'topEnd'}
      )
    } else {
      setDone(true)
      toaster.push(
        <Notification
          closable
          type="success"
          header="Indexing started"
          duration={5000}></Notification>,
        {placement: 'topEnd'}
      )
    }
  }

  return (
    <div>
      <h1>Reindex Search</h1>
      <br></br>
      <br></br>
      <Form>
        <Form.Group>
          <ButtonToolbar>
            <Button
              disabled={done || loading}
              onClick={() => {
                onSubmit().catch((e) => {
                  console.error(e)
                })
              }}>
              Reindex
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </div>
  )
}

export function CustomViewCopyData() {
  const [done, setDone] = useState(false)
  const [copyDB, {error, loading}] = useCopyDbMutation()

  async function onSubmit(direction: CopyDbDirection) {
    await copyDB({
      variables: {
        copyDBDirection: direction,
      },
    })

    if (error) {
      toaster.push(
        <Notification
          closable
          type="error"
          header={error.toString()}
          duration={5000}></Notification>,
        {placement: 'topEnd'}
      )
    } else {
      setDone(true)
      toaster.push(
        <Notification
          closable
          type="success"
          header="Copy data started"
          duration={5000}></Notification>,
        {placement: 'topEnd'}
      )
    }
  }

  return (
    <div>
      <h1>Copy Data</h1>
      <br></br>
      <br></br>
      <Form>
        <Form.Group>
          <ButtonToolbar>
            <Button
              disabled={done || loading}
              onClick={() => {
                onSubmit(CopyDbDirection.ProdToStaging).catch((e) => {
                  console.error(e)
                })
              }}>
              Copy Data to Staging
            </Button>

            {process.env.NODE_ENV === 'development' ? (
              <>
                <Button
                  disabled={done || loading}
                  onClick={() => {
                    onSubmit(CopyDbDirection.ProdToLocal).catch((e) => {
                      console.error(e)
                    })
                  }}>
                  Copy Data from prod to local MongoDB
                </Button>

                <Button
                  disabled={done || loading}
                  onClick={() => {
                    onSubmit(CopyDbDirection.ProdToDevelopment).catch((e) => {
                      console.error(e)
                    })
                  }}>
                  Copy Data from prod to Development (Atlas)
                </Button>
              </>
            ) : null}
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </div>
  )
}
