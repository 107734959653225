import React, {useState} from 'react'
import {BlockProps} from '../atoms/blockList'
import {CodeSnippetValue} from './types'
import {I18nWrapper} from '../atoms/i18nWrapper'
import {Button, Drawer, Form, Input, Panel, SelectPicker} from 'rsuite'
import sanitizeHtml from 'sanitize-html'
import {useTranslation} from 'react-i18next'
import './codeSnippetBlock.less'

export type CodeSnippetBlockProps = BlockProps<CodeSnippetValue>

const aspectRatios = ['16:9', '5:8', '4:3', '1:1', '3:4', '8:5', '9:16'].map((item) => ({
  label: item,
  value: 'aspectratio_' + item.replace(':', '_'),
}))

const layouts = [
  {
    label: 'Aspect Ratio',
    value: 'aspectRatio',
  },
]

function sanitize(dirty: string) {
  return sanitizeHtml(dirty, {
    allowedTags: [
      'address',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'blockquote',
      'dd',
      'div',
      'dl',
      'dt',
      'hr',
      'li',
      'main',
      'ol',
      'p',
      'pre',
      'ul',
      'a',
      'abbr',
      'b',
      'br',
      'dfn',
      'em',
      'i',
      'kbd',
      'mark',
      'q',
      'rb',
      'rp',
      'rt',
      's',
      'samp',
      'small',
      'span',
      'strong',
      'sub',
      'sup',
      'time',
      'u',
      'var',
      'wbr',
      'caption',
      'col',
      'colgroup',
      'table',
      'tbody',
      'td',
      'tfoot',
      'th',
      'thead',
      'tr',
      'iframe',
    ],
    allowedAttributes: {
      td: ['bgcolor'],
      a: ['href', 'target'],
      iframe: ['src', 'width', 'height', 'title'],
    },
  })
}

export function CodeSnippetBlock({
  value,
  onChange,
  languageLaneL,
  languageLaneR,
}: CodeSnippetBlockProps) {
  const {snippet, size} = value
  const {t} = useTranslation()
  const [isChooseModalOpen, setChooseModalOpen] = useState(false)

  return (
    <>
      <Panel
        bodyFill={true}
        bordered={true}
        style={{
          padding: 0,
          margin: 0,
          overflow: 'hidden',
          border: 'none',
        }}>
        <Button size={'md'} appearance={'ghost'} onClick={() => setChooseModalOpen(true)}>
          {'Edit iFrame'}
        </Button>
        <br />
        <br />
        <I18nWrapper
          lane1={
            <div
              style={{
                position: 'relative',
                width: '100%',
              }}>
              <div
                style={{
                  position: 'absolute',
                  zIndex: 1,
                  height: '100%',
                  left: 0,
                }}></div>
              <div
                style={{
                  position: 'relative',
                  paddingTop: '56.25%',
                  width: '100%',
                }}>
                {
                  <div
                    className="snippet-wrapper"
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      border: 'none',
                    }}
                    dangerouslySetInnerHTML={{__html: snippet[languageLaneL] || ''}}
                  />
                }
              </div>
            </div>
          }
          lane2={
            <div
              style={{
                position: 'relative',
                width: '100%',
              }}>
              <div
                style={{
                  position: 'relative',
                  paddingTop: '56.25%',
                  width: '100%',
                }}>
                {
                  <div
                    className="snippet-wrapper"
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      border: 'none',
                    }}
                    dangerouslySetInnerHTML={{__html: snippet[languageLaneR] || ''}}
                  />
                }
              </div>
            </div>
          }
        />
      </Panel>

      <Drawer
        open={isChooseModalOpen}
        style={{overflow: 'hidden'}}
        placement={'bottom'}
        size="full"
        onClose={() => setChooseModalOpen(false)}>
        <Drawer.Header>
          <Drawer.Title>{t('blockList.codeSnippet')}</Drawer.Title>
          <Drawer.Actions>
            <Button
              appearance={'primary'}
              color={'green'}
              onClick={() => {
                setChooseModalOpen(false)
                onChange(sanitize(snippet[languageLaneL] || ''), ['snippet', languageLaneL])
              }}>
              {t('blockList.apply')}
            </Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body style={{overflow: 'auto'}}>
          <I18nWrapper
            lane1={
              <Form>
                <Form.Group controlId="snippet">
                  <Input
                    as="textarea"
                    rows={10}
                    value={snippet[languageLaneL] || ''}
                    onChange={(val) => onChange(val, ['snippet', languageLaneL])}
                    onBlur={() => {
                      onChange(sanitize(snippet[languageLaneL] || ''), ['snippet', languageLaneL])
                    }}
                  />
                  <br />
                  <br />
                  <Button
                    onClick={() => {
                      onChange(sanitize(snippet[languageLaneL] || ''), ['snippet', languageLaneL])
                    }}>
                    Sanitize iFrame
                  </Button>
                </Form.Group>
                <Form.Group controlId="layout">
                  <Form.ControlLabel>Layout</Form.ControlLabel>
                  <SelectPicker
                    data={layouts}
                    value={size?.aspectRatio ? 'aspectRatio' : undefined}
                    searchable={false}
                    onChange={(val) => {
                      if (val === 'aspectRatio') {
                        onChange(
                          {
                            aspectRatio: {
                              desktop: aspectRatios[0].value,
                              mobile: aspectRatios[0].value,
                            },
                          },
                          ['size']
                        )
                      } else {
                        onChange(undefined, ['size'])
                      }
                    }}
                  />
                </Form.Group>
                {size?.aspectRatio && (
                  <>
                    <Form.Group controlId="desktop">
                      <Form.ControlLabel>Aspect Ratio Desktop</Form.ControlLabel>
                      <SelectPicker
                        data={aspectRatios}
                        value={size?.aspectRatio?.desktop}
                        cleanable={false}
                        searchable={false}
                        onChange={(val) => {
                          onChange(
                            {
                              aspectRatio: {
                                ...size?.aspectRatio,
                                desktop: val,
                              },
                            },
                            ['size']
                          )
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="mobile">
                      <Form.ControlLabel>Aspect Ratio Mobile</Form.ControlLabel>
                      <SelectPicker
                        data={aspectRatios}
                        value={size?.aspectRatio?.mobile}
                        cleanable={false}
                        searchable={false}
                        onChange={(val) => {
                          onChange(
                            {
                              aspectRatio: {
                                ...size?.aspectRatio,
                                mobile: val,
                              },
                            },
                            ['size']
                          )
                        }}
                      />
                    </Form.Group>
                  </>
                )}
                <p>
                  <b>Layout Verhalten mit folgender Prio:</b>
                </p>
                <ol>
                  <li>
                    Ist ein Aspect Ratio gesetzt, wird dieses verwendet. So ist es möglich für
                    Mobile ein anderes Aspect Ratio zu setzten als für Desktop. Der Breakpoint liegt
                    bei 476px
                  </li>
                  <li>
                    Ist width und height im iframe element gesetzt, wird davon das aspect ratio
                    abgeleitet
                  </li>
                  <li>Ist nur height im iframe element gesetzt, wird die fixe Höhe verwendet</li>
                  <li>Ist nichts gesetzt wird ein Aspect Ratio von 16:9 verwendet</li>
                </ol>
              </Form>
            }
            lane2={
              <Input as="textarea" rows={10} value={snippet[languageLaneR]} disabled={true} />
            }></I18nWrapper>
        </Drawer.Body>
      </Drawer>
    </>
  )
}
